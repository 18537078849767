<template>
    <div class="container">
        <el-main>
            <!-- 主干区域 -->
            <div class="content">
                <div class="service-title">
                    中学生标准学术能力测试(THUSSAT)网站用户服务协议
                </div>
                <div class="service-cont">
                    <div class="service-cont-item">
                        用户在接受北京百世百诚教育科技有限公司（以下简称“我司”）官方网站 <a href="http://www.thussat.com">www.thussat.com</a> （以下简称“本网站”）服务之前，请务必仔细阅读本协议。通过各种方式利用本网站的行为，都将被视为是对本协议全部内容的无异议的认可。
                    </div>
                    <div class="service-cont-item">
                        1、本网站提供中学生标准学术能力考试(THUSSAT)， 含诊断性测试(TDA)、基础性测试(TGA)及学科能力综合测试(TACA)的考试相关服务，为我司提供的线上服务平台，是完全独立于中学及高校的法律主体。本网站所有权属于我司，考生须同意以下全部服务条款，方可进行本网站操作。
                    </div>
                    <div class="service-cont-item">
                        2、考生本人（法定监护人）理解并同意，本网站提供的包括信息发布、考试报名、成绩查询等与考试相关服务，目的是为报考生提供便利，我司不对考生的报名结果及考试结果进行任何承诺或保证，亦不对考试结果承担任何法律责任。我司部分高严肃性考试得到高校认可，可作为申请高校部分项目时的材料，但与录取结果之间不挂钩。
                    </div>
                    <div class="service-cont-item">
                        3、考生本人（法定监护人）已认真阅读并遵守本网站提供的报名注册流程及考生须知。考生本人保证注册时提交的个人信息资料完整、真实、准确，并对因个人信息不真实或不准确而导致无法参加考试、或其他直接或间接后果负责。考生本人在提交个人信息成功注册成用户后，即已承诺对所提交信息内容的真实性、准确性、合法性负责，已注册的信息如需更改，须提起书面申请并提供相应证明文件。
                    </div>
                    <div class="service-cont-item">
                        4、考生本人（法定监护人）理解并同意，在付费过程中由于银行网上支付系统问题导致的任何异常情况，我司不承担直接、间接及连带责任。
                    </div>
                    <div class="service-cont-item">
                        5、考生本人（法定监护人）理解并同意，如果在报名过程中出现不可抗力因素导致考生无法正常报名，我司不承担直接、间接及连带责任。
                    </div>
                    <div class="service-cont-item">
                        6、考生本人（法定监护人）理解并同意，在报名成功后，由于系统出现问题导致考生本人无法参加考试，本司所负之责任仅限于协助考生重新免费报名下次考试或全额退还考试报名费，不承担其他直接、间接及连带责任。
                    </div>
                    <div class="service-cont-item">
                        7、本网站中《考试须知》内容（包括面向群体、考查内容、时间安排、考试形式、主要操作流程），是根据考试安排内容而制作。若因故临时调整报名说明，我司尽最大努力在本网站及我司微信公众平台上及时公告和调整，但因可能发生的此等行为而给报名者造成的影响或损失，我司不承担直接、间接及连带责任。
                    </div>
                    <div class="service-cont-item">
                        8、本网站无法对报名信息填报人的身份进行核对，我司向用户提供此等服务，系建立在用户善意使用的基础上。若任何第三方恶意填报他人信息，导致真实报考生遭受损失或考试影响，不应由我司承担法律责任，但我司有义务协助受害方调查相关事实。
                    </div>
                    <div class="service-cont-item">
                        9、填报人填报的个人信息，我司对数据进行去标识化处理，除法律法规规定的情形外，未经考生本人的许可我司不会向第三方公开、透露考生的个人信息。报考生个人隐私信息在其他渠道遭泄露，我司不承担直接、间接及连带责任。
                    </div>
                    <div class="service-cont-item">
                        本协议最终解释权归北京百世百诚教育科技有限公司所有。
                    </div>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>
    export default {
        name: "Service",
        data(){
            return {}
        },
        created() {
            this.$emit('getStatus',3)
        }
    }
</script>

<style scoped lang="scss">
    .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        .el-main {
            max-width: 1300px;
            margin: 6px auto;
            padding: 20px 50px;

            .content {
                background-color: #ffffff;
                padding: 60px 60px 20px 60px;
            }
        }
        .service-title{
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
        .service-cont{
            margin-top: 60px;
            .service-cont-item{
                font-size: 14px;
                line-height: 28px;
                margin: 30px 0;
                /*text-indent: 2em;*/
            }
        }
    }
</style>
